import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "WHAT TYPES OF LOANS DO YOU OFFER?",
      answer: (
        <>
          <p>
            We offer a wide range of home loans for your refinance, including:
          </p>
          <ul className="list-disc pl-6">
            <li>Conventional</li>
            <li>FHA</li>
            <li>VA</li>
            <li>Jumbo</li>
            <li>Non-QM</li>
          </ul>
        </>
      ),
    },
    {
      question: "SHOULD I REFINANCE MY MORTGAGE?",
      answer: (
        <>
          <p>
            Refinancing your home is a good idea if it’ll save you money or
            allow you to pay off your mortgage faster. It’s also a great way to
            consolidate high-interest debt by using a cash-out refinance to tap
            into your home’s equity.
          </p>
        </>
      ),
    },
    {
      question: "WHAT ARE THE COSTS ASSOCIATED WITH REFINANCING?",
      answer: (
        <>
          <p>
            Generally, you can expect to pay anywhere from 2% to 6% of your loan
            amount. The cost will depend on several factors such as your lender,
            where you live, the state of your finances, the terms, etc. Some of
            the typical fees include:
          </p>
          <ul className="list-disc pl-6">
            <li>Application fee</li>
            <li>Origination fee</li>
            <li>Credit report fee</li>
            <li>Home appraisal</li>
            <li>Home inspection</li>
            <li>Title search and insurance fee</li>
            <li>Recording fee</li>
            <li>Reconveyance fee</li>
          </ul>
        </>
      ),
    },
    {
      question: "WHAT DO I NEED TO QUALIFY FOR A REFINANCE?",
      answer: (
        <>
          <p>
            The requirements will vary depending on your lender and the terms of
            the loan. You might NOT qualify if you:
          </p>
          <ul className="list-disc pl-6">
            <li>Have poor credit</li>
            <li>Have a high debt-to-income ratio</li>
            <li>Have a lien on your home</li>
            <li>
              Are underwater on your mortgage (you owe more than your home is
              worth)
            </li>
          </ul>
        </>
      ),
    },

    {
      question: "HOW LONG DOES IT TAKE TO REFINANCE?",
      answer: (
        <>
          <p>
            Refinancing your home typically takes between 30 to 45 days to
            complete. It’s important to note, however, that any of the services
            provided by third parties (such as appraisals or inspections) may
            delay the process.
          </p>
        </>
      ),
    },
    {
      question: "WHAT’S THE DIFFERENCE BETWEEN INTEREST RATE AND APR?",
      answer: (
        <>
          <p>
            An interest rate is the percentage of your total loan balance, paid
            on a monthly basis, that you pay a lender in exchange for borrowing
            money from them.
          </p>
          <p>
            APR (annual percentage rate) is the yearly interest rate that takes
            into account all of the other charges or fees of your loan (such as
            mortgage insurance, closing costs, and loan origination fees). APR
            is a tool you can use for an apples-to-apples comparison of various
            loans.
          </p>
        </>
      ),
    },
    {
      question: "DO YOU OFFER RATE LOCKS?",
      answer: (
        <>
          <p>
            Yes, we do! Locking in a mortgage rate can protect you from
            fluctuations in the market and we’re happy to do that for you.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Refinance Pasadena  | Accel Mortgage | Refinance Your Home"
        description="Whether it’s your first refinance or you’ve been around the block before, we’ll help you analyze Pasadena loan programs to choose the one that’s right for you."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Refinance Your Home</h1>
              <p>
                Whether it’s your first refinance or you’ve been around the
                block before, we’ll help you analyze loan programs so you can
                choose the one that’s right for you and your goals. We’re a top
                mortgage broker serving Pasadena, CA, Los Angeles County, and
                all of California.
              </p>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/9.0 Self Employed/1.0 Hero.jpg"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-20">
            <h2>When Refinancing Makes Sense</h2>
          </header>
          <div className="grid gap-x-10 gap-y-16 md:grid-cols-2 lg:gap-x-20">
            <div className="flex space-x-5">
              <div className="number-text-stroke">1</div>
              <div>
                <h3 className="heading-four mb-2">You Want Extra Cash</h3>
                <p className="mb-0">
                  Borrow against your equity and get cash for projects, to pay
                  off debts, or anything else you need.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">2</div>
              <div>
                <h3 className="heading-four mb-2">
                  You Want a Lower Monthly Payment
                </h3>
                <p className="mb-0">
                  Give yourself extra room in your budget with a lower monthly
                  mortgage payment.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">3</div>
              <div>
                <h3 className="heading-four mb-2">You Want a Better Rate</h3>
                <p className="mb-0">
                  Get a lower interest rate and save yourself thousands of
                  dollars on the life of your home loan.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">4</div>
              <div>
                <h3 className="heading-four mb-2">
                  You Want To Pay Off Your Loan Sooner
                </h3>
                <p className="mb-0">
                  Pay off your mortgage faster by switching from a 30-year loan
                  to a 15-year loan, for example.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>How Refinancing Works</h2>
              <p>
                When you refinance your home, you’re simply paying off your
                existing mortgage with a new mortgage. You can also take cash
                out from home equity at the same time. Here’s how it works:
              </p>
              <ul className="styled-list-arrow">
                <li>We analyze your loan options (for free!)</li>
                <li>
                  You get a selection based on your unique criteria and scenario
                </li>
                <li>We help you compare terms and interest rates</li>
                <li>You choose the offer that best fits your needs</li>
              </ul>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/2.0 Conventional Loans/Refinance.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-10">
            <h2>Frequently Asked Questions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="border-t border-gray-700/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    uuid={i}
                    className="border-b border-gray-700/20 py-6 md:py-8"
                  >
                    <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                      <AccordionItemState>
                        {(state) => {
                          return (
                            <>
                              <p className="heading-five mb-0">
                                {faq.question}
                              </p>

                              <i
                                className={`far ${
                                  state.expanded
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                } text-base text-gray-700/50`}
                              ></i>
                            </>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                      {faq.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <ValueProps />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
